import React from 'react';
import { Modal } from '@material-ui/core';
import styled from 'styled-components';

const NoBorderModal = styled(Modal)`
  display: grid;
  outline: 0;
  min-height: 100vh;
  min-width: 100vw;
  place-content: center center;
  &:focus {
    outline: 0;
  }
`;
const NoBorderDiv = styled.div`
  outline: 0;
  &:focus {
    outline: 0;
  }
`;
export default class Takeover extends React.Component {
  componentDidMount() {
    // window.document.body.classList.add('overflow-y-hidden');
  }
  componentWillUnmount() {
    // window.document.body.classList.remove('overflow-y-hidden');
  }

  render() {
    const { children, onClick, extraClass = '' } = this.props;
    return (
      <NoBorderModal onClick={onClick} open={true}>
        <NoBorderDiv>{children}</NoBorderDiv>
      </NoBorderModal>
    );
  }
}
