import React from 'react';
import { navigate } from 'gatsby';
import '../../../static/styles/_login-modal.scss';
import TakeOver from './Takeover';

export default ({ title, delay, children }) => {
  return (
    <>
      <TakeOver
        onClick={e => {
          e.preventDefault();
          navigate('/');
        }}
      >
        <div
          className="modal"
          onClick={e => {
            e.stopPropagation();
            navigate('/');
          }}
        >
          <div className="left-half darkbluebg">
            <h2>We're in a Closed Beta</h2>
          </div>

          <div className="right-half darkbluebg">
            <p>We're in a beta period as we test the platform, please check back soon.</p>
            <p>-GivHub Team</p>
          </div>
        </div>
      </TakeOver>
    </>
  );
};
