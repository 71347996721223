import React from "react";
import HomePage from "../components/layout/Home";
import BetaAlertTakeover from "../components/common/BetaAlertTakeover";

export default props => {
  return (
    <HomePage>
      <BetaAlertTakeover />
    </HomePage>
  );
};
